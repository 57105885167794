import React from 'react'
import { PaddedContent } from '@aserto/console-common'

import rainLottie from '../../assets/rain-lottie.png'
import { useHistory } from '../../services/HistoryProvider'
import { CenterContainer, ImageContainer, LottieImg, RedirectLink } from './styles'

const DisabledAccount: React.FC = () => {
  const history = useHistory()
  return (
    <PaddedContent>
      <CenterContainer>
        <ImageContainer>
          <LottieImg src={rainLottie} />
        </ImageContainer>

        <br></br>
        <h1>Your account is disabled.</h1>
        <h3>
          Please contact us at <a href="mailto:support@aserto.com">support@aserto.com</a> if you
          wish to restore it.
        </h3>
        <br></br>
        <RedirectLink
          onClick={() => {
            history.push('/')
            window.location.reload()
          }}
        >
          Redirect to login
        </RedirectLink>
      </CenterContainer>
    </PaddedContent>
  )
}
export default DisabledAccount
