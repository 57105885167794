import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query'

import { useConsoleBackendClient } from '../clients/ConsoleBackendClient'
import {
  Ds0TenantObject,
  parseAsDs0TenantObjectProperties,
} from '../types/directoryProperties/Ds0TenantObject'
import {
  MutationKeys,
  QueryKeys,
  ReactQueryMutationHooks,
  useMutationOptions,
} from '../types/local/general'
import { RpcStatus } from '../types/local/tenant'

export const useDs0TenantObject = (
  tenantId?: string,
  options?: Omit<
    UseQueryOptions<Ds0TenantObject | undefined, RpcStatus, Ds0TenantObject, QueryKeys[]>,
    'queryKey' | 'queryFn' | 'retry' | 'staleTime'
  >
) => {
  const { get } = useConsoleBackendClient()

  const { data: tenantObject } = useQuery(
    [QueryKeys.Ds0TenantObject, tenantId as QueryKeys],
    () =>
      get<Ds0TenantObject>({
        path: `ds0/tenant`,
        headerOverrides: { 'aserto-tenant-id': tenantId! },
      }),
    {
      ...options,
      enabled: !!tenantId && options?.enabled,
      retry: false,
      staleTime: Infinity,
    }
  )

  if (!tenantObject) {
    return undefined
  }

  return {
    ...tenantObject,
    properties: parseAsDs0TenantObjectProperties(tenantObject.properties),
  }
}

export const useUpdateDs0TenantObject = (
  tenantId: string,
  reactQueryHooks: ReactQueryMutationHooks<Ds0TenantObject, Ds0TenantObject> = {}
) => {
  const mutationOptions = useMutationOptions(reactQueryHooks)
  const { put } = useConsoleBackendClient()
  const queryClient = useQueryClient()

  return useMutation(
    [MutationKeys.UpdateDs0TenantObject],
    (body) =>
      put({
        body,
        path: `ds0/tenant`,
        headerOverrides: { 'aserto-tenant-id': tenantId },
      }),
    {
      ...mutationOptions,
      onSuccess: (d, v, c) => {
        queryClient.removeQueries([QueryKeys.Ds0TenantObject, tenantId as QueryKeys])
        mutationOptions?.onSuccess?.(d, v, c)
      },
    }
  )
}
