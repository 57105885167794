import React, { PropsWithChildren } from 'react'

import { useDs0TenantObject } from '../../api/ds0Tenant'
import { useProfile } from '../ProfileAndQueryClientProvider'

type CachePreloaderProps = {
  block?: boolean
}

const CachePreloader: React.FC<PropsWithChildren<CachePreloaderProps>> = ({
  block = false,
  children,
}) => {
  const loaded: boolean[] = []
  // push anything that should be loaded before rendering
  const { tenant } = useProfile()
  loaded.push(useDs0TenantObject(tenant?.id) !== undefined || true)
  const allLoaded = !loaded.some((i) => !i)

  return block && !allLoaded ? null : <>{children}</>
}

export default CachePreloader
